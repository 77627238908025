import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"

export default () => 
<div>
    <SEO title={'Global Top 20 For Healthcare Software | ICON Worldwide'} 
    description="We're so proud to rank 14th globally for healthcare software development! Discover more on our blog!"
    canonical={'https://icon-worldwide.com/blog/top-20-for-healthcare-software'}/>

    <NewsHeader/>

    <div id="single-news">
        <h1>ICON WORLDWIDE RANKS IN <span>GLOBAL TOP 20</span> FOR HEALTHCARE SOFTWARE</h1>
        <div id="title-underline"></div>
        <h2>ICON WORLDWIDE IS PROUD TO ANNOUNCE ITS RANKING OF NUMBER 14 GLOBALLY AND ONLY SWISS AGENCY TO RANK</h2>
        <div id="single-news-texts">
            <p>Top 27 Healthcare Software Development Firms, According to SDCR – <a  rel="noopener noreferrer" target="_blank" rel="noopener noreferrer" href="https://www.abc-7.com/our-apologies">Top Software Development Companies</a>.</p>
            <p><a  rel="noopener noreferrer" target="_blank" rel="noopener noreferrer" href="https://www.softwaredevelopmentcompany.co/">SoftwareDevelopmentCompany.co</a>, a B2B marketplace connecting brands with software developers, researched and determined the leading healthcare industry software development firms from around the world. SOURCE SDCR – Top Software Development Companies.</p>
            <p><a  rel="noopener noreferrer" target="_blank" rel="noopener noreferrer" href="https://www.softwaredevelopmentcompany.co/">SoftwareDevelopmentCompany.co</a> researched the best healthcare software developers who can build custom solutions and help brands in that industry flourish.</p>
            <p><a  rel="noopener noreferrer" target="_blank" rel="noopener noreferrer" href="https://www.designrush.com/agency/mobile-app-design-development">App Design & Development Company</a></p>
        </div>

        <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
        <h4><Link to="/contact-us" className="cta cta-long">Let's start a new project together</Link></h4>

        <NewsFooter previous="number-20-for-php-developmentt" next="pharma-digital-marketing-case-studies"/>
    </div>

    <Footer noScrollbar="true"/>
</div>